<template>
  <div class="container">
    <EditList v-if="renderComponent" v-on:save-list="create" :list="list" :bookLanguage="bookLanguage"></EditList>
  </div>
</template>

<script>

import router from "@/router"
import EditList from "./components/EditList"
import moment from "moment"
import { mapActions } from 'vuex'
import { LIST_CREATE } from '../../store/List/action.type'

export default {
  props: {
    list: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  name: "CreateList",
  components: {
    EditList: EditList,
  },
  data() {
    return {
      bookLanguage: null,
    }
  },
  computed: {
    renderComponent() {
      return !window.localStorage.bookData || (window.localStorage.bookData && this.bookLanguage)
    },
  },
  mounted() {
    let bookData
    const localstorage = window.localStorage
    if (localstorage.bookData) {
      bookData = JSON.parse(localstorage.bookData)
      this.bookLanguage = bookData.language
    }
  },
  methods: {
    ...mapActions("list", [LIST_CREATE]),
    async create(list) {
      //Get book from localstorage if it is chosen
      let bookData
      const localstorage = window.localStorage
      if (localstorage.bookData) bookData = JSON.parse(localstorage.bookData)

      if (localstorage.bookData && bookData.timestamp + 3600 > moment().unix()) {
        list.book = JSON.parse(window.localStorage.bookData)
        window.localStorage.removeItem("bookData")
      }

      if (!list.name)
        return this.$buefy.toast.open({
          message: "Geef je lijst een naam",
          type: "is-danger",
          position: "is-bottom-right",
        })

      const createdList = await this[LIST_CREATE](list)

      this.$buefy.toast.open({
        message: "Je woordenlijst is opgeslagen",
        type: "is-success",
        position: "is-bottom-right",
      })

      this.$gtag.event("create", { event_category: "list", value: 1 })
      return router.push("/edit-list/" + createdList.id)
    },
  },
}
</script>

<style scoped>
.container {
  padding-top: 20px;
  padding-bottom: 20px !important;
}
.image-select {
  overflow: hidden;
}
.clear {
  clear: both;
  display: block;
}
.field {
  padding-top: 10px !important;
  margin-bottom: 0 !important;
}
span.rownumber {
  background: #fc7234;
  color: #fff;
  font-weight: 600;
  position: relative;
  margin-top: -35px;
  margin-left: 20px;
  width: 30px;
  height: 30px;
  padding: 2px;
  border-radius: 50%;
  display: block;
  line-height: 26px;
}
.textfield-label {
  color: #97a5aa;
  font-size: 0.8em;
  font-weight: 600;
  text-transform: uppercase;
}
.language {
  color: #3ccfcf;
}
input.input,
input.input:focus,
input.input:hover,
input.input:active {
  border: 0 !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.input-field {
  border-bottom: 1px solid #455358;
  width: 100%;
  height: 40px;
  margin-top: 10px;
}
.block {
  height: 100%;
}
.clickable-icon {
  position: absolute;
  bottom: 0;
}
.clickable-icon:hover {
  cursor: pointer;
}
.iconoff {
  color: #ff0000;
  opacity: 0.7;
}
.column {
  position: relative;
}
.card {
  position: absolute;
  width: calc(100% - 24px);
  z-index: 9999;
  overflow-y: scroll;
}
.is-32x32 {
  position: absolute;
  bottom: 0;
}
.image img {
  height: 100%;
  width: 100%;
}
.file {
  margin-bottom: 8px;
}
.buttons > .button {
  margin: 0 2px 8px;
}
</style>
